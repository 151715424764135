

.input-bubble__wrapper {
  @apply h-full;
}

.input-bubble {
  @apply h-full sm:w-60 rounded-full pb-0.5 text-sm px-3 order-2 text-gray-800 bg-white border border-gray-200 hover:border-gray-300 focus:border-indigo-300 placeholder-gray-400 outline-none;

  &--error {
    @apply border-red-400;

  }

}

.input-bubble__plus-button {
  @apply h-full
  rounded-full
  text-sm
  px-3
  order-2
  bg-white
  border
  border-indigo-600
  stroke-indigo-600
  hover:border-indigo-800
  hover:stroke-indigo-900
  outline-none;
}

.input-bubble__plus-icon {
  @apply stroke-inherit;
}

.input-bubble__error {
  @apply mt-1 text-red-400 text-xs;
}

.input-bubble__popup {
  @apply absolute bg-white border-gray-300 w-full rounded-lg overflow-hidden;
  border-width: 1px;
}