:root {
  /* COLORS */
  --white: #ffffff;

  /* FONTS */
  --base-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --base-font-size: 15px;
  
  /* BASE */
  --body-background: var(--white);
  --text-color: black;
}