.btn {
  @apply font-medium text-sm inline-flex items-center justify-center border border-transparent rounded leading-5 shadow-sm transition duration-150 ease-in-out cursor-pointer disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed disabled:shadow-none;

  /* SIZE */
  &--xs {
    @apply px-2 py-0.5;
  }
  &--sm {
    @apply px-2 py-1;
  }
  &--md {
    @apply px-3 py-2;
  }
  &--lg {
    @apply px-4 py-3;
  }
  &--square {
    @apply p-2;
  }

  /* THEME */
  &--primary {
    @apply bg-indigo-500 hover:bg-indigo-600 text-white disabled:hover:bg-gray-100;
  }
  &--secondary {
    @apply border-indigo-300 hover:border-indigo-500 text-indigo-500;
  }
  &--tertiary {
    @apply border-gray-200 hover:border-gray-300 text-gray-600;
  }
  &--danger {
    @apply bg-red-500 hover:bg-red-600 text-white;
  }
  &--danger-white {
    @apply border-gray-200 hover:border-gray-300 text-red-500;
  }
  &--success {
    @apply bg-green-500 hover:bg-green-600 text-white;
  }
  &--success-white {
    @apply border-gray-200 hover:border-gray-300 text-green-500;
  }
  &--transparent {
    @apply bg-transparent hover:bg-transparent shadow-none;
  }
}
