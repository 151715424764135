.file-card {
  @apply stroke-gray-800 mr-3 mt-3;

  &--inner {
    @apply cursor-pointer;
    &:hover {
      @apply text-indigo-600 underline underline-offset-1;
    }
  }

  &--text {
    @apply text-inherit whitespace-nowrap;
  }

  &--icon {
    @apply mr-1 stroke-indigo-600;
  }
}