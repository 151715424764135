.header {
  @apply sticky top-0 bg-white border-b border-gray-200 z-30;
}

.header__wrapper {
  @apply px-4 sm:px-6 lg:px-8 flex items-center justify-center;
}

.header__content {
  @apply flex items-center justify-between h-20 -mb-px w-full max-w-7xl;
}

.header__left {
  @apply flex;
}

.header__right {
  @apply flex items-center space-x-3;
}

.header__toggle {
  @apply text-gray-500 hover:text-gray-600 lg:hidden;
}

.header__toggle-icon {
  @apply w-6 h-6 fill-current;
}

.header__static {
  @apply text-gray-900 fill-gray-900 stroke-gray-900 hover:text-indigo-600 hover:fill-indigo-600 hover:stroke-indigo-600;
}

.header__static-icon {
  @apply fill-inherit stroke-inherit;
}

.header__user {
  @apply relative inline-flex;
}

.header__user-toggle {
  @apply inline-flex justify-center items-center;
}

.header__user-avatar {
  @apply w-8 h-8 rounded-full bg-gray-600;
}

.header__user-select {
  @apply flex items-center truncate;
}

.header__user-name {
  @apply truncate ml-2 text-sm font-medium group-hover:text-gray-800;
}

.header__user-icon {
  @apply w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400;
}

.header__dropdown {
  @apply origin-top-right z-10 absolute top-full bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 right-0;
  min-width: 176px;
}

.header__dropdown-header {
  @apply pt-0.5 pb-2 px-3 mb-1 border-b border-gray-200;
}

.header__dropdown-name {
  @apply font-medium text-gray-800;
}

.header__dropdown-role {
  @apply text-xs text-gray-500 italic;
}

.header__dropdown-item {
  @apply font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center py-1 px-3 cursor-pointer;
}
