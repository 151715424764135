.spinner {
  svg {
    animation: spinner-rotate 2s linear infinite;
  }

  circle {
    @apply stroke-current;
    stroke-linecap: round;
    animation: spinner-dash 1.5s ease-in-out infinite;
  }

  &--main {
    @apply text-indigo-600;
  }
  &--white {
    @apply text-white;
  }
}

@keyframes spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner-dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
