.private {
  @apply flex h-screen overflow-hidden;
}

.private__content {
  @apply relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden;
}

.private__main {
  @apply px-4 sm:px-6 lg:px-8 py-8 w-full mx-auto;
}