.input__label {
  @apply block text-sm font-medium mb-1;
}

.input__wrapper {
  @apply h-10 rounded shadow-sm;
}

.input {
  @apply w-full h-full px-3 order-2 text-sm text-gray-800 bg-white rounded border border-gray-200 hover:border-gray-300 focus:border-indigo-300 placeholder-gray-400 outline-none;

  &--textarea {
    @apply min-h-32 pt-2;
    margin-bottom: -7px;
  }
  &--textarea-fixed {
    @apply resize-none;
  }
  &--prefix {
    @apply rounded-l-none border-l-0 pl-0;
  }
  &--suffix {
    @apply rounded-r-none border-r-0 pr-0;
  }
  &--error {
    @apply border-red-400;

    ~ .input__suffix,
    ~ .input__prefix {
      @apply border-red-400;
    }
  }

  &:hover {
    ~ .input__suffix,
    ~ .input__prefix {
      @apply border-gray-300;
    }
  }
  &:focus {
    ~ .input__suffix,
    ~ .input__prefix {
      @apply border-indigo-300;
    }
  }
}

.input__suffix,
.input__prefix {
  @apply h-full px-3 border bg-white border-gray-200 text-gray-400 font-medium text-sm flex items-center;
}

.input__prefix {
  @apply order-1 border-r-0 rounded-l;
}

.input__suffix {
  @apply order-3 border-l-0 rounded-r;
}

.input__error {
  @apply mt-1 text-red-400 text-xs;
}
