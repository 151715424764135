.bubble {
  @apply min-h-8;
  &--blue {
    @apply stroke-indigo-600 fill-indigo-600 cursor-pointer;
  }
  &--green {
    @apply stroke-green-600 fill-green-600;
  }
  &--red {
    @apply stroke-red-600 fill-red-600;
  }
  &--yellow {
    @apply stroke-yellow-500 fill-yellow-500;
  }
}
