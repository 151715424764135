/* TABS */
.account-tabs__nav {
  @apply lg:border-r lg:border-gray-300 lg:min-h-96;
}
.account-tabs__list {
  @apply flex lg:block lg:pr-5;
}
.account-tabs__item {
  @apply px-0 lg:px-2 py-2 rounded-t-xl lg:rounded
  flex items-center gap-x-2
  cursor-pointer text-gray-400 whitespace-nowrap
  border-l-2
  border-t-2
  border-b-2
  bg-gray-100
  last:border-r-2
  
  lg:bg-white
  lg:border-0
  last:lg:border-0
  text-center
  w-full
  ;

  svg {
    @apply hidden lg:block;
  }

  &--active {
    @apply bg-indigo-500 border-indigo-500 text-white;
  }
}
.account-tabs__item-label {
  @apply text-gray-700 text-center w-full lg:w-auto lg:text-left;
}
.account-tabs__item--active .account-tabs__item-label {
  @apply text-white;
}
.account-tabs__section {
  @apply p-5 border-x-2 border-b-2 lg:pr-0 lg:pt-0 lg:border-0;
}

.account-tabs__item-icon {
  @apply stroke-gray-700;
}
.account-tabs__item--active .account-tabs__item-icon {
  @apply stroke-white;
}
