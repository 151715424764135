.tag {
  @apply inline-block px-4 py-1 border rounded-full text-sm font-semibold text-center text-white whitespace-nowrap;

  &--blue {
    @apply bg-indigo-600 border-indigo-600;
  }
  &--green {
    @apply bg-green-600 border-green-600;
  }
  &--red {
    @apply bg-red-600 border-red-600;
  }
  &--yellow {
    @apply bg-yellow-500 border-yellow-500;
  }

  &--outline {
    @apply bg-transparent text-current;

    &.tag--blue {
      @apply text-indigo-600;
    }
    &.tag--green {
      @apply text-green-600;
    }
    &.tag--red {
      @apply text-red-600;
    }
    &.tag--yellow {
      @apply text-yellow-500;
    }
  }
}