#root {
  height: 100%;
}

body {
  @apply bg-gray-100;
}

.container {
  margin-right: auto;
  margin-left: auto;
}
