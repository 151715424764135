.card {
  @apply bg-white shadow-lg rounded-sm border border-gray-200;
}

.card.no-border {
  @apply border-0;
}

.card-heading {
  @apply px-5 py-4 border-b border-gray-100 font-bold text-gray-800;
}

.card-content {
  @apply p-5;
}

.card-content.no-spacing {
  @apply p-0;
}
