.tabs {
  @apply w-full h-full;
}

.nav div {
  @apply 
    bg-gray-200 
    rounded-t-lg
    sm:px-5
    border-gray-300 
    border-y-2 
    border-x-0
    border-l-2
    text-center
    self-stretch
    py-2
    text-lg
    overflow-hidden
    relative
  ;

}

.nav {
  @apply relative top-0.5;
}

.nav div:first-child {
  @apply border-l-2;
}

.nav div.selected {
  @apply bg-gray-50
  border-b-gray-50
  z-20
  border-b-0
  border-x-2
  ;
}

.nav div:last-child {
  @apply border-r-2;
}

.nav div.selected + div {
  @apply border-l-0;
}

.outlet {
  @apply px-4 py-6
  bg-gray-50
  w-full
  border-gray-300
  border-x-2
  border-y-2
  min-h-56
  rounded-b-lg
  z-10
  relative;
}