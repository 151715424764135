@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Config */
@import "config/config";

/* Base */
@import "base/base";
@import "base/components";
@import "base/typography";
@import "base/layout";

/* Components */
@import "components/bubble";
@import "components/button";
@import "components/card";
@import "components/fileCard";
@import "components/header";
@import "components/input";
@import "components/inputBubble";
@import "components/icon";
@import "components/sidebar";
@import "components/table";
@import "components/tabs";
@import "components/tag";
@import "components/spinner";

/* Pages */
@import "pages/account";
@import "pages/private";

/* Shame */
@import "shame";